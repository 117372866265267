export const CONFIG = {
  logo: "/logo.png",
  site_title: "CargoTwins Management",
  Auth0Domain: "cargotwinsmanagement.us.auth0.com",
  Auth0ClientId: "QHbSZPmBbrUYyiddodldxmMAM5ddHPNY",
  Auth0Audience: "https://mapi.cargotwins.com",
  API_URL: "https://mapi.cargotwins.com/",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/clkha3odl008i01qpeqws6d21",
  websiteURL: "https://management.cargotwins.com",
  billingEnabled: false,
  deviceCost: 15,
  primaryColour: "#db80bb",
  secondaryColour: "#4287f5",
};
